import React from 'react'
import Banners from '../../Components/Banners/Banners'
import './Demo.css'
import Footer from '../../Components/Footer/Footer'
import NewProducBtn from '../../Components/NewProducBtn/NewProducBtn'
import Products from '../../Components/Products/Products'
import BannersHome from '../../Components/BannersHome/BannersHome'
import BannersModal from '../../Components/BannersModal/BannersModal'
import PublicacionesHome from '../../Components/PublicacionesHome/PublicacionesHome'
import TitleSection from '../../Components/TitleSection/TitleSection'
import logo from '../../images/logo.png'
import AuthUsuario from '../../Components/AuthUsuario/AuthUsuario';
export default function Demo() {
    return (
        <section className='demo'>
            <Banners />
            <BannersHome />
            <TitleSection title='La forma más facil y segura de comprar y vender motos en Colombia' description="Conectate directamente con vendedores, sin intermediarios, y encuentra la moto perfecta al mejor precio" logo={logo} buton={<AuthUsuario/>} link='/motos'  linkText='Explorar Motos Disponibles' />
            <BannersModal />
            <Products />
            <TitleSection title='Motos de Segunda Blog' description="Todo lo que necesitas saber sobre nuestro Blog" logo={logo} link='/blog' linkText='Ver más' />
            <PublicacionesHome />
            <NewProducBtn />
            <Footer />

        </section>
    )
}
