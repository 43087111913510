import React, { useEffect, useState } from 'react';
import { Link as Anchor } from "react-router-dom";
import './ProductosMain.css'
import baseURL from '../../url';
import moneda from '../../moneda';
export default function ProductosMain() {
    const [productos, setProductos] = useState([]);
    const [usuario, setUsuario] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseURL}/userLogued.php`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUsuario(data);
            } catch (error) {
                console.error
                    ('Error al obtener datos:', error);
            }
        };

        fetchData();
    }, []);
    useEffect(() => {
        cargarProductos();
    }, []);


    const cargarProductos = () => {
        fetch(`${baseURL}/productosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setProductos(data.productos.reverse() || []);
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };

    return (


        <div className='table-containerProductos'>
            <div className='deFlexMore'>
                <h3>Ultimos productos</h3>
                <Anchor to={`/dashboard/productos`} className='logo'>
                    Ver más
                </Anchor>
            </div>
            <table className='table'>
                <thead>
                    <tr>
                        <th>IdProducto</th>
                        <th>Titulo</th>
                        <th>Descripcion</th>
                        <th>Precio</th>
                        <th>Tipo</th>
                        <th>Imagen 1</th>
                    </tr>
                </thead>
                <tbody>
                    {productos.filter(item => usuario.rol === 'admin' || item.idUsuario === usuario.idUsuario).map(item => (
                        <tr key={item.idProducto}>
                            <td>{item.idProducto}</td>
                            <td>{item.titulo}</td>
                            <td>{item.descripcion}</td>
                            <td style={{ color: '#008000' }}>
                                {moneda} {`${item?.precio}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                            </td>

                            <td>{item.tipo}</td>
                            <td>
                                {item.imagen1 ? (
                                    <img src={item.imagen1} alt="imagen1" />
                                ) : (
                                    <span className='imgNonetd'>
                                        Sin imagen
                                    </span>
                                )}
                            </td>
                        </tr>
                    ))}

                </tbody>

            </table>
        </div>

    );
};
